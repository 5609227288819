import { createRouter, createWebHistory, createWebHashHistory, createMemoryHistory } from 'vue-router'
import LandingLayout from '@/views/layout/LandingLayout'
import Home from '@/views/pages/Home'
import PageNotFound from '@/views/pages/PageNotFound'
// Router catch exception
const routes = [
  {
    path: '/',
    component: LandingLayout,
    name: 'LandingLayout',
    redirect: { name: 'Home'},
    children: [
      {
        path: '',
        name: 'Home',
        component: Home
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    component: PageNotFound,
    // name: 'LandingLayout',
    // redirect: {name: 'PageNotFound'},
    // children: [
    //   {
    //     path: '',
    //     name: 'PageNotFound',
    //     component: PageNotFound
    //   }
    // ]
  }
]
const router = createRouter({
  mode: 'history',
  history: createWebHistory('/'),
  hash: createWebHashHistory(),
  abstract: createMemoryHistory(),
  routes, // short for `routes: routes`
})

export default router
