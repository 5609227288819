<template>
  <header id="header" class="header">
    <div class="container">
      <input type="checkbox" id="menu-hamburger">
      <div class="menu-hamburger">
        <span class="item"></span>
      </div>
      <label for="menu-hamburger"></label>
      <div class="logo">
        <router-link :to="{path: '/'}">
          <img src="/images/logo.png" alt="Logo">
        </router-link>
      </div>
      <div class="menu-right">
        <ul class="main-menu">
          <li class="active"><a href="#home">Home</a></li>
          <li><a href="https://app.kingoflegends.net/">LOH Staking</a></li>
          <li><a href="https://app-kol.kingoflegends.net/">KOL Staking</a></li>
          <li class="has-submenu">
            <a href="#ecosystem">
              <span>Ecosystem</span>
              <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.45202 13.046C7.51318 13.1348 7.59502 13.2075 7.69048 13.2576C7.78594 13.3078 7.89217 13.334 8.00002 13.334C8.10786 13.334 8.21409 13.3078 8.30956 13.2576C8.40502 13.2075 8.48686 13.1348 8.54802 13.046L14.548 4.37934C14.6175 4.27937 14.6582 4.16229 14.6658 4.04081C14.6734 3.91932 14.6475 3.79808 14.591 3.69027C14.5345 3.58245 14.4496 3.49217 14.3454 3.42924C14.2412 3.36631 14.1217 3.33314 14 3.33334H2.00002C1.87858 3.33384 1.75958 3.36743 1.65581 3.43051C1.55203 3.49359 1.46742 3.58376 1.41106 3.69133C1.35471 3.7989 1.32874 3.9198 1.33596 4.04102C1.34317 4.16225 1.3833 4.27921 1.45202 4.37934L7.45202 13.046Z" fill="white"/>
              </svg>
            </a>
            <ul class="submenu">
              <li><a href="/assets/KOLSetup17_7.exe.zip">Mainet 1.0</a></li>
              <li><a href="/assets/LOHPC.exe.zip">Mainet 2.0</a></li>
              <li><a href="#mainet-3">Mainet 3.0</a></li>
              <li><a href="https://market.kingoflegends.net/">NFT Marketplace</a></li>
            </ul>
          </li>
          <li><a href="#team">Team</a></li>
          <li><a href="#roadmap">Roadmap</a></li>
          <li><a href="#partners">Partners</a></li>
          <li><a href="/videos/user-guide.MP4" target="_blank">Video Guide</a></li>
          <li class="has-submenu">
            <a href="javascript:;">
              <span>Introduction</span>
              <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.45202 13.046C7.51318 13.1348 7.59502 13.2075 7.69048 13.2576C7.78594 13.3078 7.89217 13.334 8.00002 13.334C8.10786 13.334 8.21409 13.3078 8.30956 13.2576C8.40502 13.2075 8.48686 13.1348 8.54802 13.046L14.548 4.37934C14.6175 4.27937 14.6582 4.16229 14.6658 4.04081C14.6734 3.91932 14.6475 3.79808 14.591 3.69027C14.5345 3.58245 14.4496 3.49217 14.3454 3.42924C14.2412 3.36631 14.1217 3.33314 14 3.33334H2.00002C1.87858 3.33384 1.75958 3.36743 1.65581 3.43051C1.55203 3.49359 1.46742 3.58376 1.41106 3.69133C1.35471 3.7989 1.32874 3.9198 1.33596 4.04102C1.34317 4.16225 1.3833 4.27921 1.45202 4.37934L7.45202 13.046Z" fill="white"/>
              </svg>
            </a>
            <ul class="submenu">
              <li><a href="https://pub-cac1a1018f4a4b88a79988acf9253758.r2.dev/docs.pdf">Docs</a></li>
              <li><a href="/assets/kingchain.pdf">Slide</a></li>
              <li><a href="https://contact-184.gitbook.io/kingchain/">KINGCHAIN Whitepaper</a></li>
              <li><a href="https://docs.kingoflegends.net">KOL Whitepaper</a></li>
              <li><a href="https://land-of-heroes.gitbook.io/land-of-heroes-loh/">LOH Whitepaper</a></li>
              <li><a href="https://github.com/ContractChecker/audits/blob/main/KingOfLegends_Token_0xd9eadE302456AfF8BF8d87fF0EF77dAb1fB9230f_Smart_Contract_Security_Audit_By_ContractChecker.pdf">KOL Audit</a></li>
              <li><a href="https://github.com/ContractChecker/audits/blob/main/LandOfHeroes_Token_0xD979d11c03cc7a66028755846f77ffF0A270F753_Smart_Contract_Security_Audit_By_ContractChecker.pdf">LOH Audit</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>
<script setup>
</script>
