<template>
  <Header></Header>
  <main>
    <p>Page Not Found</p>
  </main>
  <Footer></Footer>

</template>
<script>
import Header from '@/views/layout/components/Header.vue'
import Footer from '@/views/layout/components/Footer.vue'
export default {
  components: {
    Header,
    Footer
  }
}
</script>
