<template>
  <div id="wrapper">
    <router-view></router-view>
    <Promotion/>
  </div>
</template>
<script>
import Promotion from '@/views/layout/components/Promotion.vue'
export default {
  name: 'App',
  components: {
    Promotion
  },

}
</script>
