<template>
  <div class="promotion-dialog" v-if="isShowPromotion">
    <div class="dialog-content">
      <div class="dialog-close">
        <span @click="skip24Hours" style="padding-left: 12px; padding-right: 12px;">Skip</span>
        <span @click="closePromotion">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z" fill="#24a5ff"/>
</svg>
        </span>
      </div>
      <div class="dialog-inner">
        <a href="https://kingscan.org/" target="_blank" @click="closePromotion">
          <img src="/images/promotions/banner-mainnet.jpg" alt="Promotion">
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isShowPromotion: false
    }
  },
  created () {
    // localStorage.removeItem('KOL_PROMOTION_SKIP_FEB_29')
    const skipTime = localStorage.getItem('KOL_PROMOTION_SKIP_APR_19')
    const timeValid = skipTime && skipTime > new Date().getTime()
    if (timeValid) {
      this.isShowPromotion = false
    } else {
      this.isShowPromotion = true
    }
  },
  methods: {
    closePromotion () {
      this.isShowPromotion = false
    },
    skip24Hours () {
      const currentTime = new Date().getTime() + 86400000 * 3
      localStorage.setItem('KOL_PROMOTION_SKIP_APR_19', currentTime)
      this.closePromotion()
    }
  }
}
</script>
