<template>
  <main id="main" class="main">
    <section id="home" class="section section-hero" v-if="false">
      <img src="/images/hero.jpg" alt="">
      <div class="container">
        <div class="section-inner">
          <router-link :to="{ path: '/'}" data-aos="fade-up">
            <img src="/images/logo.png" alt="Logo">
          </router-link>
          <h2 data-aos="fade-up" data-aos-delay="200">The future of gaming</h2>
        </div>
      </div>
    </section>
    <section id="home" class="section section-hero" :style="`background-image: url(${activeImage < (images.length - 1) ? images[activeImage + 1] : images[0]})`">
      <div class="container">
        <div class="section-inner" style="text-align: right">
          <div>
            <router-link :to="{ path: '/'}">
              <img src="/images/logo.png" alt="Logo">
            </router-link>
            <a href="" target="_blank">
              <img src="/images/logo-kingchain.png" alt="KOL">
            </a>
            <router-link :to="{ path: '/'}">
              <img src="/images/logo-kol.png" alt="KOL">
            </router-link>
          </div>

          <h2 data-aos="fade-up" data-aos-delay="200" style="visibility: hidden; opacity: 0">The future of gaming</h2>
        </div>
        <swiper
          :slides-per-view="3"
          :modules="modules"
          :space-between="0"
          :loop="true"
          navigation
          :autoplay="{
            delay: 5000
          }"
          effect="coverflow"
          :grab-cursor="`auto`"
          :coverflowEffect="{
            rotate: 20,
            stretch: 100,
            depth: 200,
            modifier: 1,
            slideShadows: false,
          }"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
        >
          <swiper-slide v-for="(image, index) in images" :key="index">
            <div class="slide-item">
              <img :src="image" alt="Image">
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </section>
    <section id="marquee" class="marquee">
      <h1 v-if="currencies">
        <a href="javascript:;">
          <div v-for="currency in currencies" :key="currency.code">
            <span>🌟</span>
            <img :src="`/images/icons/${currency.code.toLowerCase()}.png`" :alt="currency.code">
            <span>${{ currency.rate }}</span>
          </div>
          <div v-for="currency in currencies" :key="currency.code">
            <span>🌟</span>
            <img :src="`/images/icons/${currency.code.toLowerCase()}.png`" :alt="currency.code">
            <span>${{ currency.rate }}</span>
          </div>
        </a>
      </h1>
    </section>
    <section class="section section-about">
      <div class="container">
        <div class="section-inner">
          <div class="section-title" data-aos="fade-up" data-aos-delay="200">
            <h2>Welcome to the Future of Gaming with<br/><strong>Kingoflegends.net</strong></h2>
          </div>
          <div class="section-content">
            <div class="about-items">
              <div class="about-item">
                <div class="about-item__left" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">
                  <h3 style="text-transform: uppercase;">The future of gaming</h3>
                  <p>We are thrilled to announce an exciting upgrade to the Kingoflegends.net ecosystem.
                    Our commitment to innovation and enhancing your gaming experience has led us to this pivotal moment.
                    With great pleasure, we introduce you to the all-new $LOH token and our eagerly awaited $LOH staking program, both set to launch in late September.
                  </p>
                </div>
                <div class="about-item__right" data-aos="fade-left" data-aos-delay="200" data-aos-duration="500">
                  <a href="javascript:;">
                    <img src="/images/kol-logo.png" alt="Logo">
                  </a>
                </div>
              </div>
              <div class="about-item">
                <div class="about-item__left" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">
                  <a href="javascript:;">
                    <img src="/images/logo.png" alt="Logo">
                  </a>
                </div>
                <div class="about-item__right" data-aos="fade-left" data-aos-delay="200" data-aos-duration="500">
                  <h3>Introducing $LOH Token: The Power Behind Legends</h3>
                  <p>We are thrilled to announce an exciting upgrade to the Kingoflegends.net ecosystem.
                    Our commitment to innovation and enhancing your gaming experience has led us to this pivotal moment.
                    With great pleasure, we introduce you to the all-new $LOH token and our eagerly awaited $LOH staking program, both set to launch in late September.
                  </p>
                  <ul>
                    <li>
                      <strong>Utility:</strong> $LOH is your gateway to a realm of exclusive benefits within the Kingoflegends universe.
                      From accessing premium in-game content to participating in secret maps and boss fight map, and further for tournaments, $LOH unlocks unparalleled opportunities.
                    </li>
                    <li>
                      <strong>Community Governance:</strong> Holders of $LOH will have a say in shaping the future of Kingoflegends Finance.
                      Your voice matters, and together, we'll chart the course for our gaming ecosystem.
                    </li>
                    <li>
                      <strong>Staking Rewards:</strong> Get ready to multiply your $LOH holdings through our staking program.
                      Earning rewards has never been this exciting. Stake $LOH tokens and watch your holdings grow.
                    </li>
                  </ul>
                </div>
              </div>
              <div class="about-item">
                <div class="about-item__left" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">
                  <h3>$LOH Staking Program: Where Earnings and Gaming Converge</h3>
                  <p>
                    In late September, we will unveil our $LOH staking program, a unique opportunity for our community to not only support the ecosystem but also earn rewards while gaming.
                    Here's what you can look forward to:
                  </p>
                  <ul>
                    <li>
                      <strong>High Yield Staking:</strong> Our staking program promises attractive rewards for participants.
                      Stake your $LOH tokens and see your holdings flourish.
                    </li>
                    <li>
                      <strong>Game while Earning:</strong> Continue your gaming journey within the Kingoflegends universe while earning $LOH rewards.
                      It's a win-win for players and investors.
                    </li>
                    <li>
                      <strong>Flexible Options:</strong> We understand that every member of our community has unique preferences.
                      That's why we offer various staking options to cater to your needs.
                    </li>
                  </ul>
                  <p>
                    Stay tuned for more details about the $LOH staking program and the official launch date in late September.
                    We can't wait to embark on this thrilling adventure with you, where gaming meets DeFi in an unprecedented way.
                  </p>
                </div>
                <div class="about-item__right" data-aos="fade-left" data-aos-delay="200" data-aos-duration="500">
                  <video id="background-video" autoplay loop muted controls poster="/images/mainet-1.jpg" style="width: 100%">
<!--                    <source src="/assets/IMG_0655.MP4" type="video/mp4">-->
                    <source src="/videos/LOH_Main_1.mov" type="video/mp4">
                  </video>
                </div>
              </div>
              <div class="about-item about-item-special" data-aos="fade-up" data-aos-delay="200" data-aos-duration="500">
                <video id="background-video" autoplay loop muted controls poster="/images/mainet-1.jpg" style="width: 100%">
<!--                  <source src="/assets/IMG_0975.MP4" type="video/mp4">-->
                  <source src="/videos/LOH_Main_3.mov" type="video/mp4">
                </video>
                <div class="about-item__left">
                  <a href="#" class="btn">Join Us in Shaping the Future</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section section-download-app" id="download-app">
      <div class="container">
        <div class="section-inner" data-aos="fade-up" data-aos-delay="200" data-aos-duration="500">
          <div class="section-left">
            <h2>Get the KINGWALLET</h2>
            <p>King Wallet is a multi-chain self-custody cryptocurrency wallet and secure gateway to thousands of Web3 decentralized applications.</p>
            <p>As a secure self-custody crypto wallet, King Wallet allows you to take complete control of your crypto assets. That means no one can freeze your funds, halt your withdrawals, or take your funds without your permission.</p>
            <p>
              <a href="#" target="_blank">
                <img src="/images/appstore.jpg" alt="App Store">
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.gamespace.kingwallet" target="_blank">
                <img src="/images/googleplay.jpg" alt="Google Play">
              </a>
            </p>
          </div>
          <div class="section-right">
            <img src="/images/screen.png" alt="Screen">
          </div>
        </div>
      </div>
    </div>
    <section class="section section-ecosystems" id="ecosystem">
      <div class="container" id="mainet-1">
        <div class="section-inner">
          <div class="section-title" data-aos="fade-down" data-aos-delay="200" data-aos-duration="500">
            <h2>Game Training Mainet 2.0</h2>
          </div>
          <div class="section-content">
            <video id="background-video" autoplay loop muted controls style="width: 100%" poster="/images/thumbnail-mainet-2.png">
              <source src="/videos/user-guide.MP4" type="video/mp4">
            </video>
<!--            <iframe src="https://www.youtube.com/embed/ZQzHl7YZ6Qk?si=A1FcIctcWISuKylI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>-->
            <div class="downloads">
              <a class="download-item image" href="/assets/KOLSetup17_7.exe.zip">
                <img src="/images/download-mainet-1.png" alt="Icon">
              </a>
              <a class="download-item image" href="/assets/KOL-MAINNET-2.rar">
                <img src="/images/download-mainet-2.png" alt="Icon">
              </a>
              <a class="download-item image" href="https://apps.apple.com/app/3d-king-of-legends/id6445888234">
                <img src="/images/app-store.png" alt="Icon">
              </a>
              <a class="download-item image" href="https://play.google.com/store/apps/details?id=com.KOL.Demo&pli=1">
                <img src="/images/google-play.png" alt="Icon">
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="team" class="section section-team">
      <div class="container">
        <div class="section-inner">
          <div class="section-title" data-aos="fade-down" data-aos-delay="200" data-aos-duration="500">
            <div class="sub-title">Team members</div>
            <h2>MEET THE CREW</h2>
          </div>
          <div class="section-content">
            <div class="team-items">
              <div class="team-item" data-aos="fade-up" data-aos-delay="200" data-aos-duration="500">
                <div class="item-team__image">
                  <img src="/images/team/1.jpg" alt="Team">
                </div>
                <div class="item-team__title">Chief Financial Officer</div>
                <div class="item-team__office">Mss. Ann</div>
              </div>
              <div class="team-item" data-aos="fade-up" data-aos-delay="200" data-aos-duration="500">
                <div class="item-team__image">
                  <img src="/images/team/2.jpg" alt="Team">
                </div>
                <div class="item-team__title">Chief Marketing Officer</div>
                <div class="item-team__office">Mr. William</div>
              </div>
              <div class="team-item" data-aos="fade-up" data-aos-delay="200" data-aos-duration="500">
                <div class="item-team__image">
                  <img src="/images/team/3.jpg" alt="Team">
                </div>
                <div class="item-team__title">Chief Executive Officers</div>
                <div class="item-team__office">Mr. Robets</div>
              </div>
              <a href="https://x.com/tonytonghkba/status/1790235112451584163?s=46&t=JcA5ZBDbQ_xOf9IgefKhnA" class="team-item" data-aos="fade-up" data-aos-delay="200" data-aos-duration="500" style="color: inherit">
                <div class="item-team__image">
                  <img src="/images/team/tony-tong.jpeg" alt="TonyTong">
                </div>
                <div class="item-team__title">Senior Advisor</div>
                <div class="item-team__office">Mr. Tony Tong</div>
              </a>
              <div class="team-item" data-aos="fade-up" data-aos-delay="200" data-aos-duration="500">
                <div class="item-team__image">
                  <img src="/images/team/11.jpg" alt="Team">
                </div>
                <div class="item-team__title">Asia Pacific Marketing Representatives</div>
                <div class="item-team__office">Mr. Nathaniel Rondon</div>
              </div>
              <div class="team-item" data-aos="fade-up" data-aos-delay="300" data-aos-duration="500">
                <div class="item-team__image">
                  <img src="/images/team/4.jpg" alt="Team">
                </div>
                <div class="item-team__title">Chief Technology Officer</div>
                <div class="item-team__office">Mr. Robin</div>
              </div>
              <div class="team-item" data-aos="fade-up" data-aos-delay="300" data-aos-duration="500">
                <div class="item-team__image">
                  <img src="/images/team/5.jpg" alt="Team">
                </div>
                <div class="item-team__title">Chief Product Officer</div>
                <div class="item-team__office">Mss. Jun</div>
              </div>
              <div class="team-item" data-aos="fade-up" data-aos-delay="300" data-aos-duration="500">
                <div class="item-team__image">
                  <img src="/images/team/6.jpg" alt="Team">
                </div>
                <div class="item-team__title">Chief Growth Officer</div>
                <div class="item-team__office">Mr. Richard</div>
              </div>
              <div class="team-item" data-aos="fade-up" data-aos-delay="300" data-aos-duration="500">
                <div class="item-team__image">
                  <img src="/images/team/7.jpg" alt="Team">
                </div>
                <div class="item-team__title">Chief Operating Officer</div>
                <div class="item-team__office">Mr. Trary</div>
              </div>
              <div class="team-item" data-aos="fade-up" data-aos-delay="400" data-aos-duration="500">
                <div class="item-team__image">
                  <img src="/images/team/8.jpg" alt="Team">
                </div>
                <div class="item-team__title">Chief Customer Officer</div>
                <div class="item-team__office">Mss. Cala</div>
              </div>
              <div class="team-item" data-aos="fade-up" data-aos-delay="400" data-aos-duration="500">
                <div class="item-team__image">
                  <img src="/images/team/9.jpg" alt="Team">
                </div>
                <div class="item-team__title">Game Designer</div>
                <div class="item-team__office">Mss. Ravi</div>
              </div>
              <div class="team-item" data-aos="fade-up" data-aos-delay="400" data-aos-duration="500">
                <div class="item-team__image">
                  <img src="/images/team/10.jpg" alt="Team">
                </div>
                <div class="item-team__title">Game Operation</div>
                <div class="item-team__office">Mss. David</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="roadmap" class="section section-roadmap">
      <div class="container">
        <div class="section-inner">
          <div class="section-title" data-aos="fade-down" data-aos-delay="400" data-aos-duration="500">
            <div class="sub-title">Timeline</div>
            <h2>Roadmap</h2>
          </div>
          <div class="roadmap-items">
            <div class="roadmap-item" data-aos="fade-right" data-aos-delay="300" data-aos-duration="500">
              <div class="roadmap-item__number">01</div>
              <div class="roadmap-item__subtitle">Q4 - 2021</div>
              <div class="roadmap-item__title">Laying the Foundation</div>
              <div class="roadmap-item__content">
                <ul>
                  <li>Establishment of the founding team</li>
                  <li>Capital structuring and project development strategy</li>
                  <li>Market research and refinement of the project concept</li>
                </ul>
              </div>
            </div>
            <div class="roadmap-item" data-aos="fade-up" data-aos-delay="300" data-aos-duration="500">
              <div class="roadmap-item__number">02</div>
              <div class="roadmap-item__subtitle">Q1 - 2022</div>
              <div class="roadmap-item__title">Building the Metaverse Platform</div>
              <div class="roadmap-item__content">
                <ul>
                  <li>Development of the metaverse platform</li>
                </ul>
              </div>
            </div>
            <div class="roadmap-item" data-aos="fade-left" data-aos-delay="300" data-aos-duration="500">
              <div class="roadmap-item__number">03</div>
              <div class="roadmap-item__subtitle">Q2 - 2022</div>
              <div class="roadmap-item__title">Preparing the Ground</div>
              <div class="roadmap-item__content">
                <ul>
                  <li>Project website design</li>
                  <li>Development of smart contracts</li>
                  <li>Formulation of marketing strategies</li>
                </ul>
              </div>
            </div>
            <div class="roadmap-item" data-aos="fade-right" data-aos-delay="400" data-aos-duration="500">
              <div class="roadmap-item__number">04</div>
              <div class="roadmap-item__subtitle">Q3 - 2022</div>
              <div class="roadmap-item__title">Building Anticipation</div>
              <div class="roadmap-item__content">
                <ul>
                  <li>Pre-sale activities</li>
                  <li>Initiation of marketing strategies</li>
                  <li>Introduction of the Staking program</li>
                  <li>Building the King of Legends community</li>
                </ul>
              </div>
            </div>
            <div class="roadmap-item" data-aos="fade-up" data-aos-delay="400" data-aos-duration="500">
              <div class="roadmap-item__number">05</div>
              <div class="roadmap-item__subtitle">Q4 - 2022</div>
              <div class="roadmap-item__title">Reaching New Heights</div>
              <div class="roadmap-item__content">
                <ul>
                  <li>Listing of $KOL token on CoinMarketCap</li>
                  <li>Launch of King of Legends Game 1</li>
                </ul>
              </div>
            </div>
            <div class="roadmap-item" data-aos="fade-left" data-aos-delay="400" data-aos-duration="500">
              <div class="roadmap-item__number">06</div>
              <div class="roadmap-item__title">Q1 - 2023</div>
              <div class="roadmap-item__title">Engaging with the Community</div>
              <div class="roadmap-item__content">
                <ul>
                  <li>Gathering community feedback</li>
                  <li>Upgrading King of Legends Game 1</li>
                </ul>
              </div>
            </div>
            <div class="roadmap-item" data-aos="fade-right" data-aos-delay="500" data-aos-duration="500">
              <div class="roadmap-item__number">07</div>
              <div class="roadmap-item__subtitle">Q2 - 2023</div>
              <div class="roadmap-item__title">Expanding Horizons</div>
              <div class="roadmap-item__content">
                <ul>
                  <li>Gathering community feedback</li>
                  <li>Launching the NFT marketplace</li>
                </ul>
              </div>
            </div>
            <div class="roadmap-item" data-aos="fade-up" data-aos-delay="500" data-aos-duration="500">
              <div class="roadmap-item__number">08</div>
              <div class="roadmap-item__subtitle">Q3 - 2023</div>
              <div class="roadmap-item__title">Embracing DeFi and Gaming</div>
              <div class="roadmap-item__content">
                <ul>
                  <li>Commencing $KOL staking</li>
                  <li>Launching smart contracts for $LOH</li>
                  <li>Commencing the ICO token sale of $LOH on gempad.app</li>
                  <li>Listing of $KOL token on international exchanges</li>
                </ul>
              </div>
            </div>
            <div class="roadmap-item" data-aos="fade-left" data-aos-delay="500" data-aos-duration="500">
              <div class="roadmap-item__number">09</div>
              <div class="roadmap-item__subtitle">Q4 - 2023</div>
              <div class="roadmap-item__title">Uniting the Ecosystem</div>
              <div class="roadmap-item__content">
                <ul>
                  <li>Commencing $LOH token staking</li>
                  <li>Launch of Mainnet 2.0 game</li>
                  <li>Integration of LOH token into Mainnet 2.0</li>
                  <li>Launch of an ERC 1155 NFT collection on the NFT marketplace</li>
                </ul>
              </div>
            </div>
            <div class="roadmap-item" data-aos="fade-left" data-aos-delay="500" data-aos-duration="500">
              <div class="roadmap-item__number">10</div>
              <div class="roadmap-item__subtitle">Q1 - 2024</div>
              <div class="roadmap-item__title">Stepping Forward</div>
              <div class="roadmap-item__content">
                <ul>
                  <li>Continuing the $LOH staking program</li>
                  <li>Finalizing the integration of Mainnet 1.0 and Mainnet 2.0 games</li>
                  <li>Advancing in technology to prepare for the launch of Mainnet 3.0</li>
                </ul>
              </div>
            </div>
            <div class="roadmap-item" data-aos="fade-left" data-aos-delay="500" data-aos-duration="500">
              <div class="roadmap-item__number">11</div>
              <div class="roadmap-item__subtitle">Q2 - 2024</div>
              <div class="roadmap-item__title">A Vision Taking Shape</div>
              <div class="roadmap-item__content">
                <ul>
                  <li>Listing of $LOH token on international exchanges</li>
                  <li>Launch of the premium Mainnet 3.0 game</li>
                  <li>Integration of $KOL + $LOH tokens into Mainnet 3.0</li>
                </ul>
              </div>
            </div>
            <div class="roadmap-item" data-aos="fade-left" data-aos-delay="500" data-aos-duration="500">
              <div class="roadmap-item__number">12</div>
              <div class="roadmap-item__subtitle">Q3 - 2024</div>
              <div class="roadmap-item__title">Community Competitions</div>
              <div class="roadmap-item__content">
                <ul>
                  <li>Extend North America market and traditional gaming global market</li>
                  <li>Staking improving</li>
                </ul>
              </div>
            </div>
            <div class="roadmap-item" data-aos="fade-left" data-aos-delay="500" data-aos-duration="500">
              <div class="roadmap-item__number">13</div>
              <div class="roadmap-item__subtitle">Q4 - 2024</div>
              <div class="roadmap-item__title">Building the Unicorn Momentum</div>
              <div class="roadmap-item__content">
                <ul>
                  <li>Continuation of Staking</li>
                  <li>Mainnet 2.0 Optimization</li>
                  <li>LOH Integration</li>
                  <li>ERC-1155 NFT Expansion</li>
                </ul>
              </div>
            </div>
            <div class="roadmap-item" data-aos="fade-left" data-aos-delay="500" data-aos-duration="500">
              <div class="roadmap-item__number">14</div>
              <div class="roadmap-item__subtitle">Q1 - 2025</div>
              <div class="roadmap-item__title">Kingoflegends' next roadmap announced</div>
              <div class="roadmap-item__content">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-partner" id="partners">
      <div class="container">
        <div class="section-inner">
          <div class="section-title" data-aos="fade-up" data-aos-delay="200" data-aos-duration="500">
            <div class="sub-title">Strategic Partners</div>
            <h2>PARTNERS</h2>
          </div>
          <div class="section-content">
            <div class="partner-items">
              <div class="partner-item" data-aos="fade-up" data-aos-delay="200" data-aos-duration="500">
                <a href="https://twitter.com/HKBAclub/status/1729804024088850885?s=20">
                  <img src="/images/partners/hkba.svg" alt="Image">
                </a>
              </div>
              <div class="partner-item" data-aos="fade-up" data-aos-delay="200" data-aos-duration="500">
                <a href="https://twitter.com/HKBAclub/status/1729804024088850885?s=20">
                  <img src="/images/partners/com2000.png" alt="Image">
                </a>
              </div>
              <div class="partner-item" data-aos="fade-up" data-aos-delay="200" data-aos-duration="500">
                <a href="https://twitter.com/kingofIegends/status/1787403873751384566?t=uctp_HSNrcdIl__nhj39pg&s=19">
                  <img src="/images/partners/3.jpg" alt="Image">
                </a>
              </div>
              <div class="partner-item" data-aos="fade-up" data-aos-delay="400" data-aos-duration="500">
                <a href="https://x.com/VPS_AI/status/1798006083560395015" style="width: 100%;">
                  <img src="/images/partners/vps-ai.svg" alt="Image">
                </a>
              </div>
              <div class="partner-item" data-aos="fade-up" data-aos-delay="400" data-aos-duration="500">
                <img src="/images/partners/5.svg" alt="Image">
              </div>
              <div class="partner-item" data-aos="fade-up" data-aos-delay="400" data-aos-duration="500">
                <img src="/images/partners/6.png" alt="Image">
              </div>
              <div class="partner-item" data-aos="fade-up" data-aos-delay="400" data-aos-duration="500">
                <img src="/images/partners/7.png" alt="Image">
              </div>
              <div class="partner-item" data-aos="fade-up" data-aos-delay="400" data-aos-duration="500">
                <img src="/images/partners/8.png" alt="Image">
              </div>
              <div class="partner-item" data-aos="fade-up" data-aos-delay="400" data-aos-duration="500">
                <img src="/images/partners/9.png" alt="Image">
              </div>
              <div class="partner-item" data-aos="fade-down" data-aos-delay="400" data-aos-duration="500">
                <a href="https://x.com/thegempad/status/1702253537072144822?s=52&t=JcA5ZBDbQ_xOf9IgefKhnA">
                  <img src="/images/partners/10.png" alt="Image">
                </a>
              </div>
              <div class="partner-item" data-aos="fade-down" data-aos-delay="400" data-aos-duration="500">
                <a href="https://twitter.com/BitMartExchange/status/1704805109128146950">
                  <img src="/images/partners/bitmart.svg" alt="Image">
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import {Autoplay, Navigation, Scrollbar, A11y, EffectCoverflow} from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/swiper-vue'
import 'swiper/swiper.css'
import 'swiper/swiper-bundle.css'
// import 'swiper/css/pagination'
// import 'swiper/css/scrollbar'
function Marquee(selector, speed) {
  const parentSelector = document.querySelector(selector);
  const clone = parentSelector.innerHTML;
  const firstElement = parentSelector.children[0];
  let i = 0;
  parentSelector.insertAdjacentHTML('beforeend', clone);
  parentSelector.insertAdjacentHTML('beforeend', clone);
  setInterval(function () {
    firstElement.style.marginLeft = `-${i}px`;
    if (i > firstElement.clientWidth) {
      i = 0;
    }
    i = i + speed;
  }, 0);
}
export default {
  data() {
    return {
      modules: [Autoplay, Navigation, Scrollbar, A11y, EffectCoverflow],
      images: [
          '/images/slider/LOH-1.jpg',
          '/images/slider/LOH-2.jpg',
          '/images/slider/LOH-3.jpg',
          '/images/slider/LOH-4.jpg',
          '/images/slider/LOH-5.jpg',
          '/images/slider/LOH-6.jpg',
          '/images/slider/LOH-7.jpg',
          '/images/slider/LOH-8.jpg',
          '/images/slider/LOH-9.jpg',
          '/images/slider/LOH-10.jpg',
          '/images/slider/LOH-11.jpg',
      ],
      activeImage: 0,
      effect: '',
      priceUrl: 'https://api-kol.kingoflegends.net/api/v1/public/currencies?type=price',
      currencies: []
    }
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  async created() {
    if (window.innerWidth) {
      this.effect = 'coverflow'
    }
    try {
      const response = await fetch(this.priceUrl)
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const data = await response.json()
      this.currencies = data && data.data
      console.log(this.currencies)
      setTimeout(() => {
        Marquee('.marquee', 0.3)
      }, 300)
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
    }
  },
  beforeMount() {

  },
  methods: {
    onSlideChange(swiper) {
      this.activeImage = swiper.realIndex
    }
  }
}
</script>
