<template>
  <div id="landing-layout" :class="{'loaded': isLoaded}">
    <Header></Header>
    <router-view></router-view>
    <div id="loader-wrapper">
      <div id="loading-center-absolute">
        <div class="object" id="object_four"></div>
        <div class="object" id="object_three"></div>
        <div class="object" id="object_two"></div>
        <div class="object" id="object_one"></div>
      </div>
      <div class="loader-section section-left"></div>
      <div class="loader-section section-right"></div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from '@/views/layout/components/Header.vue'
import Footer from '@/views/layout/components/Footer.vue'
import AOS from 'aos'
import 'aos/dist/aos.css' // You can also use <link> for styles
export default  {
  data() {
    return {
      isLoaded: false
    }
  },
  components: {
    Header,
    Footer
  },
  created() {
    this.winLoad()
    setTimeout(() => {
      AOS.init({
        disable: function() {
          var maxWidth = 600;
          return window.innerWidth < maxWidth
        }
      })
    })
  },
  methods: {
    winLoad() {
      if (document.readyState === 'complete') {
        this.isLoaded = true
      } else {
        window.addEventListener("load", () => {
          this.isLoaded = true
        })
      }
    }
  }
}
</script>
