<template>
  <footer id="footer" class="footer">
    <div class="container">
      <div class="copyright">Copyright © 2022 <a href="https://kingoflegends.net/">KingofLegends</a>. All rights reserved.</div>
      <div class="socials">
        <div class="row">
          <a href="https://t.me/KingofLegends_Global">
            <img src="images/icons/telegram.svg" alt="Telegram">
          </a>
          <a href="https://twitter.com/kingofIegends">
            <img src="images/icons/twitter.svg" alt="Twitter">
          </a>
          <a href="https://www.youtube.com/@kingoflegends6231">
            <img src="images/icons/youtube.svg" alt="Youtube" style="width: 24px; height: 24px">
          </a>
        </div>
        <div class="row support" style="margin-top: 10px;">
          <a href="mailto:contact@kingoflegends.finance">
            <span style="color: #efbd47;">Contact</span>: contact@kingoflegends.finance
          </a>
          <a href="mailto:kingoflegendsgamefi@gmail.com">
            <span style="color: #efbd47;">Support</span>:
            kingoflegendsgamefi@gmail.com
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>
<script setup>
</script>
